console.log('Ready javascript');
// Mobile > all touchstar DOM perfect Iphone
document.addEventListener("touchstart", function(){}, true);

require('@fancyapps/fancybox');

var Flickity = require('flickity');
require('flickity-fade');
require('flickity-imagesloaded');


if (document.querySelector('.header__fixed')){
const divHeader = document.querySelector('.header__content');
const divHeaderFixed = document.querySelector('.header__fixed');
function detectarTamanioNavegador() {
  if (window.innerHeight < divHeader.clientHeight) {
    divHeaderFixed.classList.remove('is-fixed');
  } else {
    divHeaderFixed.classList.add('is-fixed');
  }
}

window.addEventListener('scroll', detectarTamanioNavegador);

window.addEventListener('load', detectarTamanioNavegador);

window.addEventListener('resize', detectarTamanioNavegador);

}


const hamburger = document.querySelector('.header__hamburger');
const body = document.querySelector('#body');

hamburger.addEventListener('click', function() {
  this.classList.toggle('open');
  body.classList.toggle('is-active-menu-open');
});











